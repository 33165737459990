import { lazy, Suspense, useEffect, useMemo } from 'react';
import './i18n';
import {
  appLoadingStatuses,
  AppNames,
  deleteCartDataPoints,
  getCart,
  globalRoutes,
  publishCartDataPoints,
  userPermissions,
  cartDataPointsGroups,
  platformToast,
  FeatureFlags,
} from '@cfacorp/ctrl-platform-ui-core-utils';
import RouteInfo from '@cfacorp/ctrl-platform-ui-core-utils/dist/types/RouteInfo';
import { useQueryClient } from '@tanstack/react-query';
import {
  useHasFeatureFlag,
  useSelectedStoreNumber,
} from '@cfacorp/ctrl-platform-shared-react-components';
import RouteConstants from './routes/RouteConstants';
import useHasMenuPerms from './hooks/useHasMenuPerms';
import menuIntService from './services/menuIntService';
import { menuCartDataPointGroups } from './enums/MenuDataPoints';
import useAppTranslation from './hooks/useAppTranslation';
import './index.css';
import useHasPosPerms from './hooks/useHasPosPerms';
import useHasVenuesPerms from './hooks/useHasVenuesPerms';
import { posCartDataPointGroups } from './enums/POSDataPoints';

const MenuRouter = lazy(() => import('./routes/MenuRouter'));
const POSRouter = lazy(() => import('./routes/POSRouter'));
const VenuesRouter = lazy(() => import('./routes/VenuesRouter'));

export default function Root() {
  const selectedStoreNumber = useSelectedStoreNumber();
  const { menuPermsLoaded, hasMenuPerms } = useHasMenuPerms();
  const { posPermsLoaded, hasPosPerms } = useHasPosPerms();
  const { venuesPermsLoaded, hasVenuesPerms } = useHasVenuesPerms();
  const queryClient = useQueryClient();
  const { t } = useAppTranslation();
  const hasFeatureFlag = useHasFeatureFlag(FeatureFlags.PROXY_VENUE_API);
  const venueIdFF = hasFeatureFlag
    ? t('Venues.proxyVenueId')
    : t('Venues.primaryVenueId');

  const menuRoutes: RouteInfo[] = useMemo(
    () => [
      {
        route: RouteConstants.MENU_BASE_NAME,
        displayName: 'Menu Home',
        keywords: ['menu', 'system'],
      },
      {
        route: `${RouteConstants.MENU_BASE_NAME}${RouteConstants.EQUIPMENT_FAILURE}`,
        displayName: t('AppRoutes.equipmentFailure'),
        keywords: [
          'equipment failure',
          'equip',
          'fail',
          'menu',
          'broke',
          'emergency',
          'quick action',
          'griddle',
          'grill',
          'oven',
          'down',
          'ice dream',
          'boil advisor',
          'clean',
          'sanitize',
        ],
      },
      {
        route: `${RouteConstants.MENU_BASE_NAME}${RouteConstants.INGREDIENT_BASED_LOCKS}`,
        displayName: t('AppRoutes.ingredientBasedLocks'),
        keywords: [
          'ingredient based',
          'ingredient',
          'lock',
          'item',
          'available',
          'leaf',
          'lettuce',
          'tray',
          'quick action',
        ],
      },
      {
        route: `${RouteConstants.MENU_BASE_NAME}${RouteConstants.LIMITED_MENUS}`,
        displayName: t('AppRoutes.limitedMenus'),
        keywords: ['limited menu', 'quick', 'distribut', 'contingen'],
      },
      {
        route: `${RouteConstants.MENU_BASE_NAME}${RouteConstants.MASTER_MENU}`,
        displayName: t('AppRoutes.masterMenu'),
        keywords: ['master', 'master menu', 'select'],
      },
      {
        route: `${RouteConstants.MENU_BASE_NAME}${RouteConstants.ITEM_LOCK}`,
        displayName: t('AppRoutes.itemLock'),
        keywords: ['item lock', 'lock', 'menu', 'unavailab'],
      },
      {
        route: `${RouteConstants.MENU_BASE_NAME}${RouteConstants.PRICE_OVERRIDE}`,
        displayName: t('AppRoutes.priceOverride'),
        keywords: ['price', 'override', 'item', 'device group'],
      },
      {
        route: `${RouteConstants.MENU_BASE_NAME}${RouteConstants.AUTO_SELL_CONDIMENTS}`,
        displayName: t('AppRoutes.autoSellCondiments'),
        keywords: [
          'auto sell',
          'auto',
          'sell',
          'condiment',
          'include',
          'item look-up',
        ],
      },
      {
        route: `${RouteConstants.MENU_BASE_NAME}${RouteConstants.CUSTOM_ITEM_NAMES}`,
        displayName: t('AppRoutes.customItemNames'),
        keywords: ['custom', 'item', 'name', 'text', 'label'],
      },
    ],
    [t],
  );

  const posRoutes: RouteInfo[] = useMemo(
    () => [
      {
        route: `${RouteConstants.POS_BASE_NAME}`,
        displayName: t('AppRoutes.posHome'),
        keywords: ['POS', 'point of sale'],
      },
      {
        route: `${RouteConstants.POS_BASE_NAME}${RouteConstants.CUSTOM_RECEIPT_TEXT}`,
        displayName: 'Custom Receipt Text',
        keywords: ['custom', 'receipt', 'text', 'guest'],
      },
      {
        route: `${RouteConstants.POS_BASE_NAME}${RouteConstants.LOCAL_ITEMS}`,
        displayName: t('AppRoutes.customReceiptText'),
        keywords: ['Local', 'Items'],
      },
      {
        route: `${RouteConstants.POS_BASE_NAME}${RouteConstants.TAX_EXEMPT}`,
        displayName: t('AppRoutes.taxExempt'),
        keywords: ['Tax', 'Exempt'],
      },
      {
        route: `${RouteConstants.POS_BASE_NAME}${RouteConstants.SPFB_CONFIG}`,
        displayName: t('AppRoutes.spfbConfigurations'),
        keywords: ['SPFB', 'Config', 'Configurations'],
      },
    ],
    [t],
  );

  const venuesRoutes: RouteInfo[] = useMemo(
    () => [
      {
        route: `${RouteConstants.VENUES}`,
        displayName: t('AppRoutes.venues'),
        keywords: [
          'venue',
          'distribution point',
          'adp',
          'additional',
          'food truck',
          'vendor',
        ],
      },
    ],
    [t],
  );

  useEffect(() => {
    if (menuPermsLoaded) {
      userPermissions.next({
        ...userPermissions.value,
        hasMenuAccess: hasMenuPerms,
      });
      appLoadingStatuses.next({
        ...appLoadingStatuses.value,
        menuLoaded: true,
      });
      globalRoutes.next({
        routes: [...globalRoutes.value.routes, ...menuRoutes],
      });
    }
  }, [hasMenuPerms, menuPermsLoaded, menuRoutes]);

  useEffect(() => {
    if (posPermsLoaded) {
      userPermissions.next({
        ...userPermissions.value,
        hasPOSAccess: hasPosPerms,
      });
      appLoadingStatuses.next({
        ...appLoadingStatuses.value,
        posLoaded: true,
      });
      globalRoutes.next({
        routes: [...globalRoutes.value.routes, ...posRoutes],
      });
    }
  }, [hasPosPerms, posPermsLoaded, posRoutes]);

  useEffect(() => {
    if (venuesPermsLoaded) {
      userPermissions.next({
        ...userPermissions.value,
        hasVenuesAccess: hasVenuesPerms,
      });
      appLoadingStatuses.next({
        ...appLoadingStatuses.value,
        venuesLoaded: true,
      });
      globalRoutes.next({
        routes: [...globalRoutes.value.routes, ...venuesRoutes],
      });
    }
  }, [hasVenuesPerms, venuesPermsLoaded, venuesRoutes]);

  useEffect(() => {
    cartDataPointsGroups.next({
      ...cartDataPointsGroups.value,
      [AppNames.MENU]: menuCartDataPointGroups,
    });
  }, []);

  useEffect(() => {
    cartDataPointsGroups.next({
      ...cartDataPointsGroups.value,
      [AppNames.POS]: posCartDataPointGroups,
    });
  }, []);

  useEffect(() => {
    const subscription = publishCartDataPoints.subscribe(cartGroups => {
      if (
        cartGroups.dataPoints.length &&
        cartGroups.appName === AppNames.MENU &&
        selectedStoreNumber.length
      ) {
        menuIntService
          .publishChanges(selectedStoreNumber, cartGroups.dataPoints, venueIdFF)
          .then(() => {
            publishCartDataPoints.next({
              dataPoints: [],
              appName: null,
            });
            platformToast.next({
              toastInfo: {
                content: 'Menu changes published',
                variant: 'success',
              },
            });
            getCart(selectedStoreNumber);
            queryClient.resetQueries();
          });
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [queryClient, selectedStoreNumber, venueIdFF]);

  useEffect(() => {
    const subscription = publishCartDataPoints.subscribe(cartGroups => {
      if (
        cartGroups.dataPoints.length &&
        cartGroups.appName === AppNames.POS &&
        selectedStoreNumber.length
      ) {
        menuIntService
          .publishChanges(selectedStoreNumber, cartGroups.dataPoints, venueIdFF)
          .then(() => {
            publishCartDataPoints.next({
              dataPoints: [],
              appName: null,
            });
            platformToast.next({
              toastInfo: {
                content: 'POS changes published',
                variant: 'success',
              },
            });
            getCart(selectedStoreNumber);
            queryClient.resetQueries();
          });
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [queryClient, selectedStoreNumber, venueIdFF]);

  useEffect(() => {
    const subscription = deleteCartDataPoints.subscribe(cartGroups => {
      if (
        cartGroups.dataPoints.length &&
        cartGroups.appName === AppNames.MENU &&
        selectedStoreNumber.length
      ) {
        menuIntService
          .revertChanges(selectedStoreNumber, cartGroups.dataPoints)
          .then(() => {
            deleteCartDataPoints.next({
              dataPoints: [],
              appName: null,
            });
            getCart(selectedStoreNumber);
            queryClient.resetQueries();
          });
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [queryClient, selectedStoreNumber]);

  useEffect(() => {
    const subscription = deleteCartDataPoints.subscribe(cartGroups => {
      if (
        cartGroups.dataPoints.length &&
        cartGroups.appName === AppNames.POS &&
        selectedStoreNumber.length
      ) {
        menuIntService
          .revertChanges(selectedStoreNumber, cartGroups.dataPoints)
          .then(() => {
            deleteCartDataPoints.next({
              dataPoints: [],
              appName: null,
            });
            getCart(selectedStoreNumber);
            queryClient.resetQueries();
          });
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [queryClient, selectedStoreNumber]);

  return (
    <Suspense fallback={null}>
      <MenuRouter />
      <POSRouter />
      <VenuesRouter />
    </Suspense>
  );
}
